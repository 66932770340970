.edit-token-popup-background{
    position:fixed;
    height:100%;
    width:100%;
    background-color: rgba(38, 38, 38, 0.15);
    z-index: 100;
}

.edit-token-popup{
    width:600px;
    height:688px;
    background-color: #ffffff;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 24px;
    border: solid 1px #e7ebef;
}

.edit-token-popup-title{
    margin-top:32px;
    margin-left:32px;
    float:left;
    font-family: Outfit;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.edit-token-popup-close-icon{
    height:12px;
    width:12px;
    position:absolute;
    top:24px;
    right:24px;
    cursor: pointer;
}

.edit-token-popup-divider{
    margin-top: 24px;
    fill: #eaeef1;
}

.edit-token-popup-bottom-divider{
    fill: #eaeef1;
}

.edit-token-popup-body-wrapper{
   height:515px;
   width:600px; 
   overflow-y: scroll;
   overflow-x: hidden;
   margin-bottom: -16px;
}

.edit-token-popup-body{
    height:515px;
    width:536px;
    margin-left: 32px;
    margin-top: -5px;
    margin-bottom: -17px;
    
}

.edit-token-popup-logo{
    width: 152px;
    height: 178px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.edit-token-popup-logo-description{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    display: inline-block;
}

.edit-token-popup-logo-image-wrapper{
    width: 152px;
    height: 152px;
    margin-top: 8px;
    border-radius: 76px;
    overflow: hidden;
    position: relative;
    cursor:pointer;
}

.edit-token-popup-logo-image{
    width: 152px;
    height: 152px;
    width: auto; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.edit-token-popup-description{
    margin-top: 40px;
    height:190px;
}


.edit-token-popup-input{
    margin-top: 20px;
}

.edit-token-popup-cancel-button{
    width: 91px;
    height: 38px;
    position: absolute;
    bottom:24px;
    right: 126px;
}

.edit-token-popup-save-button{
    width: 78px;
    height: 38px;
    position: absolute;
    bottom:24px;
    right: 32px;
}

.edit-token-popup-description-input{
    overflow-x: hidden;
    white-space:pre-line;  
}



@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }