.error-overlay-background{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    background:rgba(0,0,0,0.2);
}

.error-overlay-box{
    width: 353px;
    height: 396px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    background-color: #ffffff;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-overlay-title{
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #050d17;

    position: absolute;
    top: 200px;
    left: 24px;
    margin-right: 24px;
}

.error-overlay-info-text{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #5f758e;

    position: absolute;
    top: 280px;
    left: 24px;
    margin-right: 24px;
}

.error-overlay-icon{
    height: 150px;
    width: 150px;
    position: absolute;
    left: 102px;
    top: 55px;
}

.error-overlay-close-button{
    width: 305px;
    height: 38px;
    position:absolute;
    bottom:24px;
    left:24px;
}

@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }