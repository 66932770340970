.step-two-background{
    width: 600px;
    height: 360px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 80px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
}

.step-two-header{
    width: 546px;
    height: 18px;
    margin: 0 171px 4px 0;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;
    margin-left: 32px;
    margin-top: 12px;

    float:left;
}

.step-two-tokenomics-span{
    width: 507px;
    height: 30px;
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    float:left;
    margin-left: 32px;
    margin-bottom:40px;
    
}

.step-two-tokenomics-span.h1 {
    margin-bottom: 5px;
    margin-top: 20px;
}

.step-two-input-box-div-wide{
    width:520px;
    height: 82px;
    float:left;
    margin-right: 32px;
    margin-bottom: 14px;
    margin-left: 32px;
}

.step-box-input-box-description{
    width: 120px;
    height: 18px;
    margin: 0 0px 8px 8px;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    float:left
}

.step-two-input-box-wide{
    width:520px;
    height: 56px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    padding-left: 16px;
    font-family: Outfit-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.step-two-input-box-wide:hover{
    outline: none;
    border: solid 2px #6f808d;
}
.step-two-input-box-wide:focus{
    outline: none;
    border: solid 2px #1c42f0;
}



.step-two-next-button{
    width: 77px;
    height: 38px;
    margin-right: 32px;
    margin-top: 40px;
    float: right;
}
.step-two-back-button{
    width: 77px;
    height: 38px;
    margin-right: 8px;
    margin-top: 40px;
    float: right;
}



.step-two-error-text{
    width: 500px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ff1940;
    position: absolute;
    bottom: 41px;
    left: 0px;
}

.step-two-bottom-error{
    height:26px;
    border-radius: 13px;
    background-color: #F8D0D5;
    position:absolute;
    bottom: 38px;
    left:32px;
}

.step-two-error-text{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #DB162F;
    margin-right:15px;
    position: relative;
    top: -2px;
}

.step-two-error-warning-icon{
    height:16px;
    margin-top: 5px;
    margin-left:15px;
    margin-right:7px;
}