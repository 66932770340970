.tokenhub-tvc-background{
    width: 1089px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 24px;
}

.tokenhub-tvc-file-grid{
    width:1041px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 17px;
    margin-top: 84px;
    margin-left: 24px;
    margin-right: 24px;
    justify-content: center;
}

.tokenhub-tvc-file{
    width: 512px;
    height: 417px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: pre-wrap;
    
}

.tokenhub-tvc-file-code{
    white-space: pre-wrap;
}

.tokenhub-tvc-your-contract{
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
    position: absolute;
    top:32px;
    left:24px;
}

.tokenhub-tvc-original-contract{
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
    position: absolute;
    top:32px;
    left:553px;
}

.tokenhub-tvc-etherscan-link{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #204bf8;
    position: absolute;
    top:35px;
    left:1002px;
}

.tokenhub-tvc-etherscan-icon{
    width: 14px;
    height: 14px;
    position: absolute;
    top:37px;
    left:980px;
}


@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }