.overlay-background{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    background:rgba(0,0,0,0.2);
}

.overlay-box{
    width: 353px;
    height: 396px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    background-color: #ffffff;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay-waiting-for-transaction{
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #050d17;

    position: absolute;
    top: 261px;
    left: 47px;
}

.overlay-info-text{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #5f758e;

    position: absolute;
    top: 307px;
    left: 38px;
}

.overlay-grey-circle{
    width: 194px;
    height: 194px;
    border-radius: 50%;
    border: 3px solid #c9c9c9;
    position: absolute;
    top: 30px;
    left: 77px;
    z-index: -1;
}

.overlay-icon{
    height: 150px;
    width: 150px;
    position: absolute;
    left: 102px;
    top: 55px;
}

.lds-ring {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 77px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    border: 3px solid #204bf8;
    border-radius: 50%;
    animation: lds-ring 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #204bf8 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }