.mobile-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    text-align: center;
    border-radius: 24px;
    box-shadow: 0px 0px 40px rgb(98 112 170 / 0.3);
    max-width: 340;
    max-height: 600;
    }
    
.mobile-img {
height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 24px 24px 0 0;
}
    
.mobile-h2 {
font-family: Outfit, Poppins, Arial;
font-weight: 600;
text-align: center;
margin: 24px 52px 0px 52px;
font-size: 24px;
    line-height: 32px;
}
    
.mobile-p {
text-align: center;
margin-top: 16px;
font-size: 16px;
margin: 16px 32px 48px 32px;
color: #5F758E;
font-family: Outfit, Poppins, Arial;
    font-weight: 400;
    line-height: 24px;
}

@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }

    @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }

    
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
    }