.tokenhub-background2{
    width: 100%;
    margin: auto;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 60px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
}

.tokenhub-header{
    width: 248px;
    height: 40px;
    margin: 40px 129px 55px 139px;
    font-family: Outfit-Bold;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
    display: inline-block;
}

.tokenhub-create-new-token-button{
    width: 178.5px;
    height: 38px;
    border-radius: 8px;
    float:right;
    margin-top: 41px;
    margin-right: 132px;
}

.tokenhub-create-new-token-button.erc404{
    width: 178.5px;
    height: 38px;
    border-radius: 8px;
    float:right;
    margin-top: 41px;
    margin-right: 39px;
}

.tokenhub-token-slots{
    position: relative;
    margin-left: 120px;
    margin-right: 120px;
    display: grid;
    width: 90%;
    height: 100vh;
    margin-bottom: -300px;
    grid-template-columns: repeat(auto-fill, 276px);
    grid-template-rows: repeat(auto-fill, 360px);
}

.tokenhub-token-slot{
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    vertical-align: top;


    width:259px;
    height:343px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 17px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    cursor:pointer;
    transition: 100ms;
    position: relative;
    user-select: none;
    background-color: #ffffff;

}

.tokenhub-token-slot-button{
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    vertical-align: top;


    width:259px;
    height:343px;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 17px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    cursor:pointer;
    transition: 100ms;
    position: relative;
    user-select: none;
    background-color: #ffffff;

}

.tokenhub-token-slot-button:before{
    content:'';
    display:block;
    margin-top:-42%;
}

.tokenhub-token-slot:hover{
    box-shadow: 0px 0px 50px -30px #888888;
}

.tokenhub-token-slot:active{
    box-shadow: 0px 0px 50px -30px #888888;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
}

.tokenhub-token-slot-button:hover{
    box-shadow: 0px 0px 50px -30px #888888;
}

.tokenhub-token-slot-button:active{
    box-shadow: 0px 0px 50px -30px #888888;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
}


.tokenhub-create-grey-circle{
    
    position: relative;
    width: 92px;
    height: 92px;
    margin: 109px 83px 14px 84px;
    border-radius: 46px;
    background-color: #e7ebef;
}

.tokenhub-create-token-icon{
    width: 52px;
    height: 52px;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.tokenhub-create-token-span{
    width: 259px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #bdd0e0;
    display: inline-block;
}

.tokenhub-token-slot:hover .tokenhub-create-token-span{
    color: #204bf8;
}

.tokenhub-token-slot-button:hover .tokenhub-create-token-span{
    color: #204bf8;
}


.tokenhub-usertoken-type{
    float:right;
    margin-top: 27px;
    margin-right: 24px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #7a8b99;
}

.tokenhub-usertoken-icon{
    min-width: 92px;
    min-height: 92px;
    width: auto; 
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.tokenhub-usertoken-icon-wrapper{
    width: 92px;
    height: 92px;
    margin-left:84px;
    margin-top: 74px;
    margin-bottom: 16px;
    border-radius: 46px;
    overflow: hidden;
    position: relative;
}

.tokenhub-usertoken-name{
    width: 259px;
    display: inline-block;
    text-align: center;
    height: 30px;
    font-family: Outfit-bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #050d17;
}

.tokenhub-usertoken-ticker{

    height: 30px;
    font-family: Outfit;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #304e67;
}

.tokenhub-usertoken-blockchain{
    display: inline-block;
    width: 259px;
    height: 20px;
    margin-top: 4px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #304e67;
}

.tokenhub-usertoken-manage-token{
    display: inline-block;
    width: 110px;
    height: 24px;
    margin-top: 54px;
    margin-left: 75px;
    transition: 300ms;
}

.tokenhub-token-slot:hover .tokenhub-usertoken-manage-token{
    background-color: #93a9ff;
    border-radius: 5px;
}

.tokenhub-token-slot-button:hover .tokenhub-usertoken-manage-token{
    background-color: #93a9ff;
    border-radius: 5px;
}

.tokenhub-usertoken-manage-token-span{
    display: inline-block;
    width: 110px;
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #204bf8;
}

.tokenhub-buy-unlimited-slots-button{
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    width: 211px;
    height: 38px;
    border-radius: 8px;
    background-color: #e8ecfe;

    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #204bf8;

    position: absolute;
    bottom: 24px;
    left: 24px;
}

.tokenhub-buy-unlimited-slots-button:hover{
    background-color: #204bf8;
    color: #ffffff;
}

.tokenhub-bottom-seperator{
    position: relative;
    display: block;
    width:90%;
    height:100px;
    margin-bottom: -100px;

}

.tokenhub-loading-grey-circle{
    width: 194px;
    height: 194px;
    border-radius: 50%;
    border: 3px solid #c9c9c9;
    position: absolute;
    top: 30px;
    left: 77px;
    z-index: -1;
}

.tokenhub-loading-icon{
    height: 150px;
    width: 150px;
    position: absolute;
    left: 102px;
    top: 55px;
}

.tokenhub-loading-lds-ring {
    display: inline-block;
    position: absolute;
    top: 30px;
    left: 77px;
  }
  .tokenhub-loading-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    border: 3px solid #204bf8;
    border-radius: 50%;
    animation: lds-ring 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #204bf8 transparent transparent transparent;
  }
  .tokenhub-loading-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .tokenhub-loading-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .tokenhub-loading-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes tokenhub-loading-lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.tokenhub-loading-box{
    width: 353px;
    height: 396px;
    background-color: #ffffff;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tokenhub-loading-animation-background{
    width:100vh;
    height:80vh;
}
  

@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }