.tokenhub-tv-background{
    width: 100%;
    height: calc(100vh + 233px);
    position: relative;
    overflow: scroll;
}

@-moz-document url-prefix() {
    .tokenhub-tv-background{
        height: calc(100vh - 96px);;
    }
}

.tokenhub-tv-header{
    width: 1089px;
    min-height: 178px;
    padding-bottom: 40px;
    margin-top: 41px;
    margin-left: auto;
    margin-right:auto;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position:relative;
    display: block;
}

.tokenhub-tv-logo-box{
    width: 92px;
    height: 92px;
    position: absolute;
    top: 43px;
    left: 40px;
    border-radius: 46px;
    overflow: hidden;
}

.tokenhub-tv-logo{
    min-width: 92px;
    min-height: 92px;
    width: auto; 
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.tokenhub-tv-name-ticker{
    position: absolute;
    top: 40px;
    left: 148px;

    font-family: Outfit-bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tv-ticker{
    font-family: Outfit;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
}

.tokenhub-tv-chain-info{
    height:20px;
    position: absolute;
    left: 148px;
    top: 74px;
    display: flex;
}

.tokenhub-tv-chain-name{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
}

.tokenhub-tv-circle-icon{
    width: 8px;
    height: 8px;
    margin-top: 7px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 4px;
    background-color: #bdd0e0;
}

.tokenhub-tv-description-and-socials{
    position: relative;
    left: 148px;
    top: 118px;
    width:516px;
    height: 242px;
}

.tokenhub-tv-description{
    width:516px;
    overflow:hidden;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
}

.tokenhub-tv-socials{
    margin-top: 24px;
    height: 18px;
}



.tokenhub-tv-read-more{
    color:#204bf8;
    cursor: pointer;
}

.tokenhub-tv-pause-button{
    width: 142.1px;
    height: 38px;
    position:absolute;
    right:24px;
    bottom:24px;

}

.tokenhub-tv-pause-icon{
    height: 11.1px;
    margin-right: 9.9px;
}

.tokenhub-tv-resume-button{
    width: 142.1px;
    height: 38px;
    position:absolute;
    right:24px;
    bottom:24px;

}

.tokenhub-tv-snapshot-button{
    width: 142.1px;
    height: 38px;
    position:absolute;
    right:175px;
    bottom:24px;
}

.tokenhub-tv-tab-select{
    margin-top: 22px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    height:34px;
    width:258px;
    grid-template-columns: auto auto;
}

.tokenhub-tv-tab{
    height:34px;
    width:129px;
    position: relative;
    text-align: center;
    margin: auto;
}

.tokenhub-tv-active-step-highlight{
    position: absolute;
    bottom:0px;
    left:0px;
    fill: #204bf8;
}

.tokenhub-tv-active-tab-title{
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
}

.tokenhub-tv-inactive-tab-title{
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-notification-green-bg{
    background-color: #e8fae5;
    color: #158900;
}
.tokenhub-notification-orange-bg{
    background-color: #fff9e5;
    color: #8d6c00;
}
.tokenhub-notification-red-bg{
    color: #860919;
    background-color: #fbe7e9;
}
.tokenhub-notification-green-header{
    color: #1fc900;
}
.tokenhub-notification-orange-header{
    color: #cc9d00;
}
.tokenhub-notification-red-header{
    color: #db162f;
}
.tokenhub-notification{
    position: fixed;
    top: 143px;
    right: 24px;
    width: 350px;
    min-height: 79px;
    border-radius: 12px;
    z-index: 1;
}
.tokenhub-notification-text{
    float: left;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    margin-top: 4px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
}
.tokenhub-notification-header{
    float: left;
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 71px;
}

.tokenhub-notification-close-button{
    background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    position: absolute;
    top: 12px;
    right: 24px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    width:18px;
    height: 18px;

}
.tokenhub-notification-close-button:focus{
    cursor: pointer;
}

.tokenhub-tv-description-popup-background{
    position:fixed;
    height:100%;
    width:100%;
    background-color: rgba(38, 38, 38, 0.15);
    z-index: 100;
}


.tokenhub-tv-description-popup{
    width:600px;
    background-color: #ffffff;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 24px;
    border: solid 1px #e7ebef;
}

.tokenhub-tv-description-popup-title{
    margin-top:32px;
    margin-left:32px;
    float:left;
    font-family: Outfit;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tv-description-popup-close-icon{
    height:12px;
    width:12px;
    position:absolute;
    top:24px;
    right:24px;
    cursor: pointer;
}

.tokenhub-tv-description-popup-divider{
    margin-top: 24px;
    fill: #eaeef1;
}

.tokenhub-tv-description-popup-description{
    float:left;
    width: 536px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tv-edit-button{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    width: 56px;
    height: 18px;
    position: absolute;
    top:19px;
    right:24px;
}

.tokenhub-tv-edit-button-icon{
    width: 12px;
    height: 12px;
    margin-left: 9px;
    margin-top: auto;
    margin-bottom: auto;
}

.tokenhub-tv-edit-button-text{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 9px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
}

.tokenhub-tv-social-icon{
    height: 14px;
    position: relative;
    top:2px;
}

.tokenhub-tv-social-hyperlink{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
    margin-left: 6px;
    text-decoration: none
}


@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }