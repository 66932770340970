.header-background{
    width: 100%;
    height: 102px;
    background-color: #e7ebef;
    overflow: auto;
}

.header-logo-image{
  width: 194px;
  height: 25px;
}

.header-logo{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 194px;
  height: 25px;
  margin-left: 139px;
  margin-top: 38.5px;
}

@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  .header-logo-text{
    margin: 1.2px 0 2.2px 10.8px;
    width: 73px;
    height: 25px;
    font-family: Outfit;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
  }

  .header-button{
    position: absolute;
    top:32px;
    right:139px;
    width: 164px;
    height: 38px;
  }

  .header-button-text{
    width: 95px;
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    position: absolute;
    top:10px;
    right:24px;
  }

  .header-button-text-connected{

    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

.header-connect-wallet-icon{
  height:14px;
  width:14px;
  position:absolute;
  top:12px;
  left:22px;

}

  

  @font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
