.step-three-background{
    width: 600px;

    margin: auto;
    margin-top: 24px;
    margin-bottom: 80px;
    padding-bottom: 100px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;

    overflow-y:auto;
    overflow-x: hidden;
}

.step-three-header{
    width: 546px;
    height: 18px;
    margin: 0 171px 4px 0;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;
    margin-left: 32px;
    margin-top: 32px;

    float:left;
}

.step-three-features-span{
    width: 507px;
    height: 30px;
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    float:left;
    margin-left: 32px;
    margin-top: 15px;
    
}
.step-three-features-span.base-token{
    height: 15px;
}

.step-three-feature-div{
    width: 536px;
    height: 80px;
    margin: 28px 32px 0px 32px;
    border-radius: 8px;
    float:right;
    border: solid 2px #b5cada;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: 0.1s;
}

.step-three-feature-div.disabled{
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
}

.step-three-fee-expand{
    width: 536px;
    min-height: 447px;
    margin: 28px 32px 0px 32px;
    border-radius: 8px;
    float:right;
    border: solid 2px #b5cada;
    position: relative;
    transition: 0.1s;
}


.step-three-fee-wallet-input{
    width: 488px !important; 
}

.step-three-fee-wallet-div{
    margin-left:24px;
    margin-top: 96px;
}

.step-three-custom-swap-router-input{
    width: 488px !important; 
}

.step-three-fee-custom-swap-router-div{
    margin-left:24px;
    margin-top: 16px;
}


.step-three-swap-router-input-description{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    float:left;
    margin-top: 16px;
    margin-left: 32px;
}

.step-three-fee-router-select{
    box-sizing: border-box;
    width: 488px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    white-space: nowrap;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    float:left;
    margin-top: 8px;
    margin-left: 24px;
    position: relative;
}

.step-three-fee-router-select:hover{
    border: solid 2px #6f808d;
}

.step-three-fee-router-selected{
    position: absolute;
    top:16px;
    left:16px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.step-three-fee-router-select-arrow{
    position: absolute;
    top: 23.5px; right: 23.5px;
  }




.step-three-router-select-box{
    box-sizing: border-box;
    width: 488px;
    max-height: 203px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #1c42f0;
    background-color: #ffffff;
    position:absolute;
    top: 220px;
    left: 24px;
    z-index: 100000;
    overflow: hidden;
}

.step-three-router-select-box-seperator{
    position: absolute;
    left:16px;
    top:54px;
    fill: #bdd0e0;
}

.step-three-router-select-item-top{
    height: 40px;
    width: 468px;
    margin-left: 6px;
    margin-top: 8px;
    margin-bottom: 8px;

}

.step-three-router-select-item-top:hover{
    background-color: #204bf8;
    cursor: pointer;
    color: #ffffff;
}

.step-three-router-select-item-span-top{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    position: absolute;
    top: 16px;
    left: 16px;
}

.step-three-router-select-items{
    width: 480px;
    max-height: 146px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.step-three-router-select-item{
    margin-left: 6px;
    position: relative;
    float: left;
    height: 38px;
    width: 488px;
}

.step-three-router-select-item:hover{
    background-color: #204bf8;
    cursor: pointer;
    color: #ffffff;
}

.step-three-router-select-item-span{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    position: absolute;
    top: 9px;
    left: 9px;
}

.builder-input-background{
    width: 100%;
    overflow:auto; position:absolute; top: 181px; right:3px; bottom:0
}

/* width */
.step-three-router-select-items::-webkit-scrollbar {
    width: 4px;
    height: 93px;
  }
  
  /* Track */
  .step-three-router-select-items::-webkit-scrollbar-track {
    height: 93px;
    border-radius: 2px;
  }
  
  /* Handle */
  .step-three-router-select-items::-webkit-scrollbar-thumb {
    background: #bdd0e0;
    border-radius: 2px;
    height: 93px;
    background-clip: padding-box;
}

.step-three-fee-precentage-header{
    width: 440px;
    display: inline-block;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    margin-top: 16px;
    margin-left: 32px;
}



.step-three-fee-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 45px; /* Set a specific slider handle width */
    height: 32px; /* Slider handle height */
    background: #204bf8; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 4px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  }

  .step-three-fee-slider::-moz-range-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 45px; /* Set a specific slider handle width */
    height: 32px; /* Slider handle height */
    background: #204bf8; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 4px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  }

  .step-three-fee-percentage{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    position:absolute;
    bottom:8px;
    pointer-events: none;
  }

  .step-three-fee-slider-grid{
    position: relative;
    justify-items: center;
    margin-top: 8px;
    margin-left: 24px;
    margin-right: 24px;
    display: grid;
    height:62px;
    width:488px;
    grid-template-columns: auto auto auto;
    padding:0;
    column-gap: 13px;
    margin-bottom: 12px;
  }

  .step-three-fee-slider-grid-item{
    height:62px;
    width: 154px;
    position:relative
  }

  .step-three-fee-slider-grid-item-description{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;
    margin-top: 8px;
}

.step-three-fee-slider{
    margin-top: 4px;
    width: 154px;
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    height: 40px; /* Specified height */
    background: #bdd0e0; /* Grey background */
    border-radius: 8px;
    outline: none; /* Remove outline */
}

.step-three-max-fee-info{
    display: block;
    position: relative;
    font-family: Outfit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    margin-top: 12px;
    margin-left: 24px;
    margin-bottom: 37px;
}

.step-three-feature-div:hover{
    border: solid 2px #6f808d;
}

.step-three-feature-div-selected{
    width: 536px;
    height: 80px;
    margin: 28px 32px 0px 32px;
    border-radius: 8px;
    float:right;
    border: solid 2px #1c42f0;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: 0.1s;
}

.step-three-feature-div-selected:hover{
    border: solid 2px #6f808d;
}

.step-three-fee-div-for-unselect{
    height: 80px;
    width: 536px;
    position: absolute;
    cursor:pointer
}

.step-three-fee-div-for-unselect:hover .step-three-fee-expand{
    border: solid 2px #6f808d;
}


.step-three-not-all-features-span{
    width: 351px;
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #304e67;
    float:right;
    margin-right: 22px;
    margin-top:24px;

}

.step-three-learn-why{
    width: 65px;
    height: 18px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #204bf8;
    float:right;
    margin-right: 58px;
    margin-top:24px;
}

.step-three-paid-features-title-span{
    width: 261px;
    height: 23px;
    margin: 42px 0 0px 34px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
    float:left;
}

.step-three-feature-circle{
    width: 64px;
    height: 64px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    background: #eff2f5;

    position: absolute;
    top:8px;
    left: 8px;

}

.step-three-feature-circle-selected{
    width: 64px;
    height: 64px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    background: #204bf8;

    position: absolute;
    top:8px;
    left: 8px;

}

.step-three-feature-icon{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.step-three-feature-icon-selected{
    width: 33px;
    height: 30px;
    position: absolute;
    top:25px;
    left: 24px;
}


.step-three-feature-title{
    width: 313px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top:19px;
    left: 88px;
}

.step-three-feature-description{
    width: 418px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    position: absolute;
    top:43px;
    left: 88px;
}

.step-three-feature-price{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #204bf8;
    position:absolute;
    top:10px;
    right:10px;
  
}

.step-three-feature-price.before-discount {
    text-decoration: line-through;
    text-decoration-color: #8a8a8a; /* Use your primary color here */
    text-decoration-thickness: 2px;
    color: #b8b8b8;
    right:70px;
}

.step-three-feature-title-selected{
    width: 313px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    position: absolute;
    top:19px;
    left: 88px;
}

.step-three-feature-description-selected{
    width: 388px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    position: absolute;
    top:43px;
    left: 88px;
}

.step-three-feature-price-selected{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: 204bf8;
    position:absolute;
    top:10px;
    right:10px;
  
}



.step-three-back-button{
    width: 77px;
    height: 38px;
    margin: 112px 0 0 127.1px;
    border-radius: 8px;
    position:absolute;
    bottom:32px;
    right:117px;
}

.step-three-next-button{
    width: 77px;
    height: 38px;
    margin: 112px 0 0 127.1px;
    border-radius: 8px;
    position:absolute;
    bottom:32px;
    right:32px;
}

.step-three-error-text{
    width: 500px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ff1940;
    position: absolute;
    bottom: 41px;
    left: 0px;
}

.step-three-bottom-error{
    height:26px;
    border-radius: 13px;
    background-color: #F8D0D5;
    position:absolute;
    bottom: 38px;
    left:32px;
}

.step-three-error-text{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #DB162F;
    margin-right:15px;
    position: relative;
    top: -2px;
}

.step-three-error-warning-icon{
    height:16px;
    margin-top: 5px;
    margin-left:15px;
    margin-right:7px;
}

@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  