.buildersteps-background{
    width: 100%;
    height: 79px;
    position: relative;
}

.top-divider{
    display: inline-block;
    vertical-align: top;
    fill: #cedae4;
}

.bottom-divider{
    position: absolute;
    bottom:0px;
    left:0px;
    fill: #cedae4;
}

.builder-steps{
    position: absolute;
    top:0px;
    left:0px;
    display: grid;
    height:79px;
    width:100%;
    grid-template-columns: auto auto auto auto;
}

.builder-step{
    height:79px;
    width:163px;
    position: relative;
    text-align: center;
    margin: auto;
}

.builder-step-was-active{
    cursor: pointer;
}

.step-icon{
    width:52px;
    height: 52px;
    margin-left: 5px;
    margin-top: 13.5px;
    margin-bottom: 13.5px;
    float:left;
}

.builder-step-title-active{
    width: 57px;
    height: 23px;
    font-family: Outfit-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    float:left;
    margin-top: 17px;
    margin-left: 5px;
}

.builder-step-description-active{
    width: 91px;
    height: 18px;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    float:left;
    margin-top: 3px;
    margin-left: 5px;
}


.builder-step-title-inactive{
    width: 57px;
    height: 23px;
    font-family: Outfit-Medium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;

    float:left;
    margin-top: 17px;
    margin-left: 5px;
}

.builder-step-description-inactive{
    width: 91px;
    height: 18px;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;

    float:left;
    margin-top: 3px;;
    margin-left: 5px;
}

.active-step-highlight{
    position: absolute;
    bottom:0px;
    left:0px;
    fill: #204bf8;
}

.builder-input-background{
    width: 100%;
    overflow:auto; position:absolute; top: 181px; right:3px; bottom:0
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 338px;
  }
  
  /* Track */
::-webkit-scrollbar-track {
    height: 338px;
    border-radius: 2px;
  }
  
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #bdd0e0;
    border-radius: 10px;
    height: 338px;
    background-clip: padding-box;

}


@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }