.step-four-background{
    width: 600px;

    margin: auto;
    margin-top: 24px;
    margin-bottom: 80px;
    padding-bottom: 60px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;


}

.step-four-header{
    width: 546px;
    height: 18px;
    margin: 0 171px 4px 0;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;
    margin-left: 32px;
    margin-top: 32px;

    float:left;
}

.step-four-summary-span{
    width: 507px;
    height: 30px;
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    float:left;
    margin-left: 32px;
}

.step-four-token-logo-wrapper{
    width: 92px;
    height: 92px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 124px;
    margin-bottom: 10px;
    border-radius: 46px;
    overflow: hidden;
    position: relative;
}

.step-four-token-logo{
    min-width: 92px;
    min-height: 92px;
    width: auto; 
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}




.step-four-token-name{
    height: 30px;
    font-family: Outfit;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #050d17;
}

.step-four-token-ticker{
    height: 30px;
    margin: 0 0 0 8px;
    font-family: Outfit;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #304e67;
}

.step-four-blockchain{
    margin-top: 6px;
    display: block;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #304e67;
}

.step-four-description{
    display:block;
    height: 18px;
    margin-top: 20px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a8b99;
}

.step-four-free-feature-header{
    display:block;
    height: 18px;
    width:400px;
    margin-top: 20px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a8b99;
    text-align: left;
    margin-left: 32px;
}

.step-four-description-text{
    display: block;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #304e67;
    margin-left: 32px;
    margin-right: 32px;

}

.step-four-tokenomics-text{
    display: block;
    height: 23px;
    margin-top: 48px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #050d17;
  
}

.step-four-tokenomics-grid{
    display: grid;
    height:60px;
    width:100%;
    grid-template-columns: auto auto auto;
}

.step-four-tokenomic-grid-item{
    width:200px;   
}

.step-four-feature-div{
    width: 536px;
    height: 80px;
    margin: 0px 32px 0px 32px;
    border-radius: 8px;
    float:right;
    position: relative;
}

.step-four-empty-div{
    width:100%;
    height:15px;
    display: inline-block;
}

.step-four-feature-circle{
    width: 64px;
    height: 64px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    background: #eff2f5;

    position: absolute;
    top:8px;
    left: 8px;

}

.step-four-feature-icon-404{
    width: 28px;
    height: 28px;

    position: absolute;
    top:19px;
    left: 19px;
}

.step-four-feature-title{
    width: 313px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top:19px;
    left: 88px;
}

.step-four-feature-description{
    width: 388px;
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    position: absolute;
    top:43px;
    left: 88px;
}

.step-four-feature-price{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #204bf8;
    position:absolute;
    top:10px;
    right:10px;
  
}

.step-four-to-pay-div{
    position: relative;
    display: inline-block;
    width: 536px;
    height: 110px;
    margin: 40px 0;
    border-radius: 16px;
    background-color: #e7ebef;
    
}

.step-four-to-pay-text{
    height: 30px;
    font-family: Outfit;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    left:24px;
    top:35px;
}
.step-four-to-pay-text.currency{

    height: 30px;
    font-family: Outfit;
    font-size: 12px;
    position: absolute;
    left:24px;
    top:65px;
}

.step-four-to-pay-text.fee{
    height: 30px;
    font-family: Outfit;
    font-size: 12px;
    position: absolute;
    left:100px;
    top:46px;
}

.step-four-to-pay-amount.saved{
    font-size: 13px;
    position: absolute;
    right:24px;
    top:65px;
}

.step-four-to-pay-amount{
    width: 188px;
    height: 30px;
    font-family: Outfit;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #204bf8;

    position: absolute;
    right:24px;
    top:35px;
}


.step-four-back-button{
    width: 77px;
    height: 38px;
    position:absolute;
    bottom:32px;
    left:190.7px;
}

.step-four-pay-button{
    width: 290.3px;
    height: 38px;
    margin: 112px 0 0 127.1px;
    position:absolute;
    bottom:32px;
    right:32px;
}

.step-four-pay-button-greyed-out{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    width: 290.3px;
    height: 38px;
    margin: 112px 0 0 127.1px;
    border-radius: 8px;
    box-shadow: 0 0 0 0 rgba(32, 75, 248, 0.3);
    background-color: #5f5f5f;

    position:absolute;
    bottom:32px;
    right:32px;

    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    text-align:center;
    padding: 0px;
}

@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }