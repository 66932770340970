.tokeninfo-background{
    width:100%px;
    height:800px;
    position: relative;
}

.tokeninfo-table{
    width:800px;
    margin: 0;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border: 1px;
}  

.tokeninfo-table-row:nth-child(odd){
    background-color: #bdbdbd;
}
