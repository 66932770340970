.tokenhub-tvt-background{
    width: 1089px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 24px;
}

.tokenhub-tvt-supply-info-grid{
    justify-items: center;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    height:157px;
    width:1041px;
    grid-template-columns: auto auto auto;
    padding:0;
    column-gap: 16px;
}

.tokenhub-tvt-supply-info-grid-item{
    width: 336.3px;
    height: 157px;
    border-radius: 16px;
    background-color: #e7ebef;
    position: relative;
}

.tokenhub-tvt-supply-info-grid-item-description{
    float:left;
    height: 18px;
    margin-left: 24px;
    margin-top: 24px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a8b99;
}

.tokenhub-tvt-supply-info-grid-item-value{
    height: 50px;
    font-family: Outfit-bold;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    bottom: 24px;
    left:24px;
  
}

.tokenhub-tvt-feature-grid{
    width:1041px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 17px;
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
    justify-content: center;
}

.tokenhub-tvt-feature{
    width: 512px;
    height: 451px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
}

.tokenhub-tvt-feature-double-width{
    grid-column: span 2;
    width: 1041px;
    height: 557px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
}

.tokenhub-tvt-feature-header-circle{
    width: 32px;
    height: 32px;
    background-color: #d1dafd;
    border-radius: 16px;
    position: absolute;
    top: 20px;
    left: 24px;
}

.tokenhub-tvt-feature-header-icon{
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.tokenhub-tvt-feature-title{
    height: 23px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;

    position: absolute;
    top: 24px;
    left: 64px;
}

.tokenhub-tvt-blacklist-input{
    box-sizing: border-box;
    width: 464px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    position: absolute;
    top: 71px;
    left: 24px;
    padding-right: 165px;
    overflow: scroll;
    white-space: nowrap;

    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tvt-blacklist-input:focus {
    outline: none;
    border: solid 2px #1c42f0;
  }

  .tokenhub-tvt-blacklist-button{
    width: 142.1px;
    height: 38px;
    position: absolute;
    top: 80px;
    right: 33px;
}

.tokenhub-tvt-blacklist-search-input{
    box-sizing: border-box;
    width: 464px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    position: absolute;
    top: 160px;
    left: 24px;

    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tvt-blacklist-search-input:focus {
    outline: none;
    border: solid 2px #1c42f0;
}

.tokenhub-tvt-blacklist-grey-circle{
    height:92px;
    width:92px;
    border-radius: 46px;
    background-color: #e7ebef;
    position: absolute;
    top: 200px;
    left: 210px;
}

.tokenhub-tvt-blacklist-empty-span{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #304e67;
    display: table;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 305px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.tokenhub-tvt-blacklist-address-list{
    position: absolute;
    top:232px;
    left:32px;
    width: 478px;
    height: 195px;
    overflow-y: scroll;
}

.tokenhub-tvt-blacklist-address-list-item{
    float:left;
    width: 448.1px;
    height: 40px;
}

.tokenhub-tvt-blacklist-address-list-item-address{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
}

.tokenhub-tvt-blacklist-address-list-item-remove-button{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    float:right;

    padding-top: 6px;
    font-family: Outfit;
    font-size: 18px;
    color: #304e67;
}

.tokenhub-tvt-blacklist-address-list::-webkit-scrollbar {
    width: 4px;
    height: 338px;
  }
  
  /* Track */
  .tokenhub-tvt-blacklist-address-list::-webkit-scrollbar-track {
    height: 338px;
    border-radius: 2px;
  }
  
  /* Handle */
  .tokenhub-tvt-blacklist-address-list::-webkit-scrollbar-thumb {
    background: #bdd0e0;
    border-radius: 2px;
    height: 153px;
    background-clip: padding-box;

  }

.tokenhub-tvt-input{
    box-sizing: border-box;
    width: 464px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    white-space: nowrap;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tvt-input:focus {
    outline: none;
    border: solid 2px #1c42f0;
  }

.tokenhub-tvt-whale-lp-input{
    position: absolute;
    top: 97px;
    left: 24px;
}

.tokenhub-tvt-input-with-description-input{
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.tokenhub-tvt-input-with-description{
    height: 82px;
    width: 464px;
}

.tokenhub-tvt-whale-lp{
    position: absolute;
    top: 71px;
    left: 24px;
}

.tokenhub-tvt-input-label{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    position: absolute;
    left:8px;
    top:0px;
}

.tokenhub-tvt-whale-max-tokens-on-wallet{
    position: absolute;
    top: 177px;
    left: 24px;
}

.tokenhub-tvt-whale-max-tokens-transaction{
    position: absolute;
    top: 283px;
    left: 24px;
}

.tokenhub-tvt-input-unit{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #7a8b99;
    position:absolute;
    bottom: 18px;
    right: 16px;
}

.tokenhub-tvt-button{
    width: 144px;
    height: 38px;
}

.tokenhub-tvt-whale-save-button{
    position: absolute;
    bottom: 24px;
    right: 24px;
}

.tokenhub-tvt-disabled{
    opacity: 0.4;
}

.tokenhub-tvt-burn-quantity-available-label{
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    position: absolute;
    left:32px;
    top:136px;
}

.tokenhub-tvt-burn-quantity-available{
    font-family: Outfit;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top: 160px;
    left: 32px;
}

.tokenhub-tvt-burn-amount{
    position: absolute;
    top: 234px;
    left: 24px;
}

.tokenhub-tvt-burn-button{
    position: absolute;
    right: 9px;
    top: 35px;
}

.tokenhub-tvt-snapshot-last-taken-label{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    
    position: absolute;
    top: 71px;
    left: 24px;
}

.tokenhub-tvt-snapshot-last-taken-date{
    font-family: Outfit;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
  
    position: absolute;
    top: 93px;
    left: 24px;
}

.tokenhub-tvt-snapshot-history-label{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    
    position: absolute;
    top: 167px;
    left: 24px;
}

.tokenhub-tvt-snapshot-list{
    position:absolute;
    top: 202px;
    left: 24px;
    width: 484px;
    height: 220px;
    overflow-y: scroll;
}

.tokenhub-tvt-snapshot-list::-webkit-scrollbar {
    width: 4px;
    height: 338px;
  }
  
  /* Track */
  .tokenhub-tvt-snapshot-list::-webkit-scrollbar-track {
    height: 338px;
    border-radius: 2px;
  }
  
  /* Handle */
  .tokenhub-tvt-snapshot-list::-webkit-scrollbar-thumb {
    background: #bdd0e0;
    border-radius: 2px;
    height: 153px;
    background-clip: padding-box;

  }

.tokenhub-tvt-snapshot-list-item{
    display: block;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    height: 20px;
    margin-bottom: 16px;
}

.tokenhub-tvt-fee-transfer{
    position: absolute;
    top:71px;
    left:24px;
}
.tokenhub-tvt-fee-burn{
    position: absolute;
    top:177px;
    left:24px;
}
.tokenhub-tvt-fee-lp{
    position: absolute;
    top:283px;
    left:24px;
}
.tokenhub-tvt-fee-wallet{
    position: absolute;
    top:389px;
    left:24px;
}

.tokenhub-tvt-fee-exclude-header{
    font-family: Outfit;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top: 24px;
    left: 553px;
}

.tokenhub-tvt-fee-exclude-input{
    position: absolute;
    top: 71px;
    left: 553px;
}

.tokenhub-tvt-fee-exlude-button{
    position: absolute;
    top: 80px;
    left: 864px;
}

.tokenhub-tvt-fee-search-input{
    box-sizing: border-box;
    width: 464px;
    height: 56px;
    padding-left: 38px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    position: absolute;
    top: 160px;
    left: 553px;

    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.tokenhub-tvt-fee-search-input:focus {
    outline: none;
    border: solid 2px #1c42f0;
}

.tokenhub-tvt-fee-address-list{
    position: absolute;
    top:232px;
    left:561px;
    width: 478px;
    height: 239px;
    overflow-y: scroll;
}

.tokenhub-tvt-fee-address-list-item{
    float:left;
    width: 448.1px;
    height: 40px;
}

.tokenhub-tvt-fee-address-list-item-address{
    height: 20px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
}

.tokenhub-tvt-fee-address-list-item-remove-button{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    float:right;

    padding-top: 6px;
    font-family: Outfit;
    font-size: 18px;
    color: #304e67;
}

.tokenhub-tvt-fee-address-list::-webkit-scrollbar {
    width: 4px;
    height: 338px;
  }
  
  /* Track */
  .tokenhub-tvt-fee-address-list::-webkit-scrollbar-track {
    height: 338px;
    border-radius: 2px;
  }
  
  /* Handle */
  .tokenhub-tvt-fee-address-list::-webkit-scrollbar-thumb {
    background: #bdd0e0;
    border-radius: 2px;
    height: 153px;
    background-clip: padding-box;

  }

  .tokenhub-tvt-fee-save-button{
    position: absolute;
    bottom:24px;
    right:24px;
  }

  .tokenhub-tvt-fee-pause-button{
    width: 142.1px;
    height: 38px;
    position: absolute;
    bottom: 24px;
    right: 183.9px;
}

.tokenhub-tvt-fee-resume-button{
    width: 142.1px;
    height: 38px;
    position: absolute;
    bottom: 24px;
    right: 183.9px;
}

.tokenhub-tvt-fee-pause-icon{
    height: 11.1px;
    margin-right: 9.9px;
}

.tokenhub-tvt-fee-search-icon{
    width:14px;
    height: 14px;
    position: absolute;
    top: 181px;
    left: 569px;
}

.tokenhub-tvt-blacklist-search-icon{
    width:14px;
    height: 14px;
    position: absolute;
    top: 181px;
    left: 40px;
}

.tokenhub-tvt-input-padding{
    box-sizing: border-box;
    padding-right: 165px;
    overflow-x: scroll;
}

.tokenhub-tvt-blacklist-empty-icon{
    width:40px;
    height:40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.tokenhub-tvt-mint-amount{
    position: absolute;
    top: 196px;
    left: 24px;
}

.tokenhub-tvt-mint-address{
    position: absolute;
    top: 90px;
    left: 24px;
}

.tokenhub-tvt-supply-after-mint-label{
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;

    position: absolute;
    left:32px;
    top:300px;
}

.tokenhub-tvt-supply-after-mint{
    font-family: Outfit;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top: 324px;
    left: 32px;
}



@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }