.input-default{
    box-sizing: border-box;
    width: 536px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    white-space: nowrap;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.input-default:hover {
    border: solid 2px #6f808d;
  }

.input-default:focus {
    outline: none;
    border: solid 2px #1c42f0;
  }

.input-default-with-description{
    position:relative;
    float: left;
    height: 82px;
    width: 536px;
}


.input-default-description{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    position: absolute;
    left:8px;
    top:0px;
}

.input-default-description-input{
    position: absolute;
    bottom: 0px;
    left: 0px;

    box-sizing: border-box;
    width: 536px;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    white-space: nowrap;
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;
}

.textarea-default{
    resize:none;
    padding-top: 8px;
    position: absolute;
    top: 26px;
    left: 0px;
    height:164px;
}

.input-default-description-input:hover {
    border: solid 2px #6f808d;
  }

.input-default-description-input:focus {
    outline: none;
    border: solid 2px #1c42f0;
  }

  .default-button{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 8px;
    box-shadow: 0 0 0 0 rgba(32, 75, 248, 0.3);
    background-color: #204bf8;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-align:center;
    padding: 0px;
    
}

.default-button-secondary{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 8px;
  box-shadow: 0 0 0 0 rgba(32, 75, 248, 0.3);
  background-color: #e8ecfe;
  font-family: Outfit;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #204bf8;
  text-align:center;
  padding: 0px;
  
}

.default-button:hover{
    background-color: #456AF9;
    outline: 2px solid #B1B8D3;
    outline-offset: 2px;
}

.default-button:active{
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    outline: 2px solid #1c42f0;
    outline-offset: 2px;
  }

.default-button-secondary:hover{
    background-color: #d2dbfd;
    outline: 2px solid #B1B8D3;
    outline-offset: 2px;
}

.default-button-secondary:active{
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
    outline: 2px solid #1c42f0;
    outline-offset: 2px;
  }

  .default-button-alert{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 8px;
    box-shadow: 0 0 0 0 rgba(32, 75, 248, 0.3);
    background-color: #ffe7b1;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a77300;
    text-align:center;
    padding: 0px;
    
  }
  
  .default-button-alert:hover{
      outline: 2px solid #b1b8d3;
      outline-offset: 2px;
  }
  
  .default-button-alert:active{
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
      outline: 2px solid #a7731d;
      outline-offset: 2px;
    }






@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }