.step-one-background{
    width: 600px;
    height: 800px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 80px;
    border-radius: 24px;
    border: solid 1px #e7ebef;
    position: relative;
}

.step-one-header{
    width: 546px;
    height: 18px;
    margin: 0 171px 4px 0;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5f758e;
    margin-left: 32px;
    margin-top: 32px;

    float:left;
}

.step-one-basic-informations-span{
    width: 507px;
    height: 30px;
    font-family: Outfit-Bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    float:left;
    margin-left: 32px;
    margin-bottom:40px;
    
}

.drag-coin-image-div{
    width: 152px;
    height: 178px;
    margin: 0px 32px 32px 32px;
    float:left;
}

.drag-image-icon{
    min-width: 152px;
    min-height: 152px;
    width: auto; 
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}

.drag-image-icon-wrapper{
    width: 152px;
    height: 152px;
    border-radius: 76px;
    overflow: hidden;
    position: relative;
    cursor:pointer;
}

.coin-image-description{
    display: inline-block;
    vertical-align: top;
    height: 18px;
    margin: 0 74px 8px 8px;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    white-space: nowrap;
}

.step-one-token-name-input-div{
    width:352px;
    height: 82px;
    float:right;
    margin-right: 32px;
    margin-bottom: 14px;
    z-index: 1000;
}


.step-one-input-box-description{
    height: 18px;
    margin: 0 0px 8px 8px;
    font-family: Outfit-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #304e67;
    float:left
}

.step-one-token-name-input:focus{
    outline: none;
    border: solid 2px #1c42f0 !important; 
}
.step-one-token-name-input:hover{
    outline: none;
    border: solid 2px #6f808d;
}



.step-one-token-name-input{
    width:336px;
    height: 56px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    padding-left: 16px;
    font-family: Outfit-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}




.step-one-token-name-input-div-wide{
    width:536px;
    height: 82px;
    float:left;
    margin-right: 32px;
    margin-bottom: 24px;
    margin-left: 32px;
    position:relative;
}

.step-one-token-name-input-wide{
    width:520px;
    height: 56px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    padding-left: 16px;
    font-family: Outfit-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

.step-one-token-name-input-wide:hover{
    outline: none;
    border: solid 2px #6f808d;
}
.step-one-token-name-input-wide:focus{
    outline: none;
    border: solid 2px #1c42f0;
}

.step-one-blockchain-combobox-outline-wide{
    width:520px;
    height: 56px;
    border-radius: 8px;
    border: solid 2px #b5cada;
    padding-left: 16px;
    float:left;
    position: relative;
    cursor: pointer;
}

.step-one-blockchain-combobox-outline-wide:hover{
    filter:brightness(110%);
    border: solid 2px #1c42f0
}

.step-one-next-button{
    width: 77px;
    height: 38px;
    margin: 112px 0 0 127.1px;
    position:absolute;
    bottom:32px;
    right:32px;
}

.step-one-blockchain-logo{
    height:24px;
    width:24px;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    user-select: none;
}

.step-one-dropdown-polygon{
    position: absolute;
    top: 23.5px; right: 23.5px;
  }

.step-one-blockchain-name{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #050d17;

    position: absolute;
    top:18px;
    left:48px;
}





.step-oneblockchain-dropdown-div{
    position: absolute;
    top: 26px;
    width: 542px;
    z-index: 1;
    margin-right:-10px;
    box-sizing: border-box;
    max-height: 253px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #1c42f0;
    background-color: #ffffff;
    overflow: hidden;
}
.step-oneblockchain-dropdown-div-seperator{
    position: absolute;
    left:16px;
    top:54px;
    fill: #bdd0e0;
}

.step-one-blockchain-dropdown-items{
    width: 532px;
    max-height: 196px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.step-one-blockchain-dropdown-item{
    margin-left: 6px;
    position: relative;
    float: left;
    height: 38px;
    width: 530px;
}

.step-one-blockchain-dropdown-item:hover{
    background-color: #204bf8;
    cursor: pointer;
    color: #ffffff;
}


.blockchain-dropdown-item-logo{
    user-select: none; 
    height:24px;
    width:24px;
    margin-left: 5px;
    position: absolute;
    top:9px;
    left:5px;

}

.step-one-block-dropdown-item-name{
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: inherit;
    user-select: none; 

    position: absolute;
    top:9px;
    left:42px;
}


.step-one-blockchain-dropdown-item-top{
    height: 40px;
    width: 526px;
    margin-left: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.step-one-blockchain-dropdown-item-top:hover{
    background-color: #204bf8;
    cursor: pointer;
    color: #ffffff;
}


.blockchain-dropdown-item-logo-top{
    user-select: none; 
    height:24px;
    width:24px;
    margin-left: 10px;
    position: absolute;
    top:18px;

}

.step-one-block-dropdown-item-name-top{
    user-select: none; 
    font-family: Outfit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: inherit;

    position: absolute;
    top:18px;
    left:48px;
}

.step-one-minus-button{
    width: 13px;
    height: 35px;
    font-family: Outfit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5f758e;

    position:absolute;
    right:34px;
    top:34px;
    user-select: none;
    cursor:pointer;
}

.step-one-plus-button{
    width: 13px;
    height: 35px;
    font-family: Outfit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5f758e;

    position:absolute;
    right:10px;
    top:36.5px;
    user-select: none;
    cursor:pointer;
}

.step-one-token-channels{
    width: 536px;
    height: 120px;
    float:left;
}

.step-one-token-channel{
    display:block;
    height: 18px;
    margin: 16px 0 16px 56px;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #204bf8;
}


.step-one-bottom-error{
    height:26px;
    border-radius: 13px;
    background-color: #F8D0D5;
    position:absolute;
    bottom: 38px;
    left:32px;
}

.step-one-error-text{
    font-family: Outfit;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #DB162F;
    margin-right:15px;
    position: relative;
    top: -2px;
}

.step-one-error-warning-icon{
    height:16px;
    margin-top: 5px;
    margin-left:15px;
    margin-right:7px;
}

.input-error-text{
    margin-left: 8px;
    color: #db162f;
}

.input-error{
    border: solid 2px #d51a2d !important;
}

.step-one-text-area{
    padding-top: 10px;
    resize: none;
}

  



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7a8b99;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7a8b99;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #7a8b99;
  }

@font-face {
    font-family: 'Outfit-Medium';
    src: local('Outfit-Medium'), url(../fonts/Outfit-Medium.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@font-face {
    font-family: 'Outfit-Bold';
    src: local('Outfit-Bold'), url(../fonts/Outfit-Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'Outfit-Regular';
    src: local('Outfit-Regular'), url(../fonts/Outfit-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
@font-face {
    font-family: 'Outfit';
    src: local('Outfit'), url(../fonts/Outfit-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }